.root,
.detachedContainer {
  --aa-panel-border-color-rgb: #000;
  --aa-panel-border-color-alpha: 0.3;
  --aa-input-border-color-rgb: #000;
  --aa-input-border-color-alpha: 0.8;
  --aa-overlay-color-rgb: #000;
  --aa-overlay-color-alpha: 0.4;
  --aa-search-input-height: 35px;
}

.detachedSearchButtonPlaceholder {
  display: none;
}

.detachedSearchButton {
  width: 50px;
  background: var(--aa-search-btn-bg);
}

.detachedSearchButtonIcon {
  color: var(--aa-text-color-rgb);
  cursor: pointer;
}

.pannel {
  z-index: 200;
  border-radius: 10px;
  margin-top: 24px;
}

.input {
  font-size: 12px;
  color: var(--aa-text-color-rgb);
}

.input::placeholder {
  color: var(--aa-text-color-rgb);
  opacity: 1; /* Firefox */
}

.form {
  border: 0px;
  background-color: var(--aa-bg-color);
  fill: #fff;
  border-radius: 10px;
  padding: 7px 10px;
}

.form svg {
  fill: var(--aa-primary-color-rgb);
}

.algolia-search-container .form {
  background-color: #000;
}

.list,
.header {
  margin: 15px;
}

@media screen and (min-width: 992px) {
  .form {
    padding: 7px 10px;
  }

  .input {
    font-size: 14px;
  }
}

@media screen and (min-width: 1300px) {
  .root {
    --aa-search-input-height: 46px;
    --aa-spacing-half: 40px;
  }
  .input {
    font-size: 18px;
  }
}
